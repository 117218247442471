<template>
  <main-menu />
  <router-view />
  <main-footer />
</template>

<script>
import MainMenu from './components/MainMenu.vue'
import MainFooter from './components/MainFooter.vue';

export default {
  components: {
    MainMenu, MainFooter
  }
}
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body{
  background-color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}

main{
    padding: 0 10%;
}

select{
  cursor: pointer;
  color: #000;
}

a{
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

h1{
    font-size: 35px;
    line-height: 1.5;
    font-weight: 400;
}

h2{
    font-size: 30px;
    font-weight: 400;
}

ul{
  list-style-type: none;
}

button{
  background: #153075;
  border: none;
  color: #fff;
  border-radius: 10px;
  padding: 12px 24px;
  font-size: 12px;
  cursor: pointer;
}

input[type="text"], input[type="password"]{
  outline: none;
  border: none;
  background: transparent;
  border-bottom: 1px solid #000;
  padding: 3px 0;
  font-size: 14px;
}

.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

@media screen and (max-width: 576px){
  main{
    padding: 0 5%;
  }

  h1{
    font-size: 28px;
  }

  h2{
    font-size: 25px;
  }

  button{
    border-radius: 12px;
    padding: 14px 28px;
  }
}
</style>
