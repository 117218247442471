<template>
    <lk-popup :showlk="showlk" @hidePopUp="hidePopUp"/>
    <nav>
    <div class="menu">
        <div class="menu-left-block">
        <a href="/#" class="menu-left-block-left">
            <img src="../img/logo.png" alt="">
            <div class="logo-text">
                Агенство <br>
                недвижимости <br>
                ODream</div>
        </a>
        <div class="menu-left-block-right">
            <ul>
                <a href="/#rent"><li>Аренда</li></a>
                <a href="/buy"><li>Покупка</li></a>
                <a href="/#offer"><li>Сдать / Продать</li></a>
                <a href="/#faq"><li>FAQ</li></a>
                <a href="/#scheme"><li>Схема</li></a>
                <a href="#contacts"><li>Контакты</li></a>
            </ul>
        </div>
        </div>
        <div class="menu-right-block">
            <div class="menu-contacts-block">
                <a href="tel:79104929145">+7 (910) 492-91-45</a>
                <a href="tel:79137705961">+7 (913) 770-59-61</a>
                <a href="mailto:odream0011@gmail.com">odream0011@gmail.com</a>
            </div>
            <img src="../img/sign_in.svg" alt="" @click="showPopUp()" v-if="Object.entries($store.state.user_info).length === 0">
            <img src="../img/sign_in.svg" alt="" onclick="window.location.href = '/lk'" v-else>
        </div>
    </div>

    <div class="menu-mobile" :class="{'menu-mobile-border': openMobileMenu == false}">
        <a href="/#" class="menu-mobile-logo">
            <img src="../img/logo.png" alt="">
            <div class="logo-text">
                Агенство <br>
                недвижимости <br>
                ODream</div>
        </a>

        <div class="menu-mobile-button">
            <img src="../img/menu_burger.svg" alt="" @click="openMobileMenu = !openMobileMenu">
        </div>

        <transition name="slide-fade">
        <div class="menu-mobile-content" v-if="openMobileMenu">
            <ul>
                <a href="/#rent" @click="openMobileMenu = false"><li>Аренда</li></a>
                <a href="/buy" @click="openMobileMenu = false"><li>Покупка</li></a>
                <a href="/#offer" @click="openMobileMenu = false"><li>Сдать / Продать</li></a>
                <a href="/#faq" @click="openMobileMenu = false"><li>FAQ</li></a>
                <a href="/#scheme" @click="openMobileMenu = false"><li>Схема</li></a>
                <a href="#contacts" @click="openMobileMenu = false"><li>Контакты</li></a>
                <a alt="" @click="openMobileMenu = false; showPopUp()" v-if="Object.entries($store.state.user_info).length === 0"><li>Личный кабинет</li></a>
                <a alt="" @click="openMobileMenu = false;" onclick="window.location.href = '/lk'" v-else><li>Личный кабинет</li></a>
            </ul>

            <div class="menu-mobile-contacts-block">
                <a href="tel:79104929145">+7 (910) 492-91-45</a>
                <a href="tel:79137705961">+7 (913) 770-59-61</a>
                <a href="mailto:odream0011@gmail.com">parking.sf16@gmail.com</a>
            </div>
        </div>
        </transition>
    </div>
    </nav>
</template>

<script>
import LkPopup from '../components/LKPopUp.vue'

export default{
    components: {
        LkPopup
    },

    data(){
        return{
            showlk: false,
            openMobileMenu: false
        }
    },

    methods: {
        showPopUp(){
            this.showlk = true
        },

        hidePopUp(){
            this.showlk = false
        }
    }
}
</script>

<style lang="scss" scoped>
.menu-mobile{
    display: none;
}

.menu{
    width: 100%;
    height: 85px;
    padding: 10px 10%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ededed;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #153075;

    .menu-left-block{
        display: flex;
    }

    .menu-left-block-left{
        display: flex;
        height: 100%;
        margin-right: 60px;

        img{
            height: 100%;
        }
        .logo-text{
            margin-left: 10px;
            font-size: 12px;
            margin-top: auto;
            margin-bottom: auto;
            line-height: 1.7;
        }
    }

    .menu-left-block-right{
        margin-top: auto;
        margin-bottom: auto;

        ul{
            display: flex;
            list-style-type: none;
            
            li{
                padding: 0 16px;
                font-size: 12px;
                cursor: pointer;
            }
        }
    }

    .menu-right-block{
        display: flex;
        height: 100%;

        img{
            height: 60%;
            margin-top: auto;
            margin-bottom: auto;
            cursor: pointer;
        }

        .menu-contacts-block{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 20px;

            a{
                font-size: 12px;
                line-height: 1.8;
            }

            a:last-child{
                font-size: 10px;
            }
        }
    }
}

@media screen and (max-width: 576px){
    .menu{
        display: none;
    }

    .menu-mobile-border{
        border-bottom: 2px solid #153075 !important;
    }

    .menu-mobile{
        display: flex;
        justify-content: space-between;
        height: 80px;
        padding: 10px 3%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #ededed;
        width: 100%;
        max-width: 100vw;
        border-bottom: 2px solid transparent;

        .menu-mobile-logo{
            display: flex;
            height: 100%;
            margin-right: 60px;

            img{
                height: 100%;
            }
            .logo-text{
                margin-left: 10px;
                font-size: 12px;
                margin-top: auto;
                margin-bottom: auto;
                line-height: 1.5;
            }
        }

        .menu-mobile-button{
            img{
                height: 60%;
                margin-top: 20%;
                width: 100%;
            }
        }

        .menu-mobile-content{
            display: block;
            position: fixed;
            top: 80px;
            left: 0;
            background-color: #ededed;
            width: 100%;
            border-bottom: 2px solid #153075;
            
            ul{
                text-align: center;
                font-size: 16px;
                
                li{
                    margin-top: 25px;
                }
            }

            .menu-mobile-contacts-block{
                margin-top: 50px;
                margin-bottom: 20px;

                a{
                    display: block;
                    margin-bottom: 10px;
                    font-size: 14px;
                    text-align: center;
                }
            }
        }
    }
}
</style>