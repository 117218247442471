<template>
    <main>
    <div class="header-main">
        <div class="header-right-block-mob">
            <img src="../img/parking_main_mob.jpg" alt="">
        </div>
        <div class="header-left-block">
            <h1>
                Охраняемый <br>
                отапливаемый паркинг <br>
                в ЖК бизнес-класса <br>
                “Серебряный фонтан”
            </h1>
            <div class="header-left-block-buttons">
                <button onclick="window.location.href='/#rent'">Арендa</button>
                <button onclick="window.location.href='/buy'">Покупка</button>
            </div>
        </div>
        <div class="header-right-block">
        </div>
    </div>

    <a name="rent"></a>
    <div class="main-rent-block">
        <h2>Аренда машино-места</h2>
        <div class="main-rent-block-buttons">
            <div class="rent-button">
                <img src="../img/B2.png" alt="">
                <div class="rent-button-content">
                    
                    <p><b>ЖК “Серебряный фонтан”</b></p>
                    <p>10 000 руб./мес.</p>
                    <p>Москва, ул. Новоалексеевская, д. 16, К2</p>

                    <button onclick="window.location.href='/rent?k=2'">Выбрать место</button>
                </div>
            </div>
            <div class="rent-button">
                <img src="../img/B1.png" alt="">
                <div class="rent-button-content">
                    
                    <p><b>ЖК “Серебряный фонтан”</b></p>
                    <p>9 000 руб./мес.</p>
                    <p>Москва, ул. Новоалексеевская, д. 16, К3</p>
                    <button onclick="window.location.href='/rent?k=3'">Выбрать место</button>
                </div>
            </div>
            <div class="rent-button">
                <img src="../img/B4.png" alt="">
                <div class="rent-button-content">
                    
                    <p><b>ЖК “Серебряный фонтан”</b></p>
                    <p>от 8 000 руб./мес.</p>
                    <p>Москва, ул. Новоалексеевская, д. 16, К4</p>
                    <button onclick="window.location.href='/rent?k=4'">Выбрать место</button>
                </div>
            </div>
        </div>
    </div>

    <a name="offer"></a>
    <div class="main-rentout-block">
        <h2>Сдать / продать машино-место</h2>
        <div class="main-rentout-block-content">
            <div class="main-rentout-block-img">
            </div>

            <div class="main-rentout-block-img-mob">
                <img src="../img/rentout.webp" alt="">
            </div>
            
            <div class="main-rentout-block-form">

                <div class="form-item">
                    <select class="form-select" v-model="offer.type">
                        <option value="" selected disabled>Выберите цель</option>
                        <option>Аренда</option>
                        <option>Продажа</option>
                    </select>
                </div>


                <div class="form-item">
                    <select class="form-select" v-model="offer.building">
                        <option value="" selected disabled>Выберите корпус</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                    </select>
                </div>

                <div class="form-item">
                    <select class="form-select" v-model="offer.floor">
                        <option value="" selected disabled>Выберите этаж</option>
                        <option>-1</option>
                        <option>-2</option>
                    </select>
                </div>

                <div class="form-item">
                    <select class="form-select" v-model="offer.size">
                        <option value="" selected disabled>Выберите размер</option>
                        <option>13,3</option>
                        <option>14,8</option>
                    </select>
                </div>

                <div class="form-item">
                    <select class="form-select" v-model="offer.section">
                        <option disabled selected value="">Выбрать секцию</option>
                        <option value="all">Все секции</option>
                        <option v-for="section in 10" :value="section" :key="section">Секция {{ section }}</option>
                    </select>
                </div>

                <div class="form-item">
                <p>Введите номер места:</p>
                <input type="text" id="parking" v-model="offer.title">
                </div>

            <div class="form-item">
                <p>Введите номер телефона:</p>
                <input type="text" id="phone" v-model="offer.phone">
            </div>

            <div class="form-item">
                <p>Желаемая цена сдачи/продаж:</p>
                <input type="text" id="price" v-model="offer.price">
            </div>

            <div class="form-item">
                <p>Желаемый срок сдачи:</p>
                <input type="text" id="time" v-model="offer.time">
            </div>

            <div class="form-item">
                <button @click="sendOffer()">Отправить заявку</button>
            </div>
            </div>
        </div>
        
    </div>

    <a name="faq"></a>
    <div class="main-question-block">
        <h2>Частые вопросы</h2>

        <div class="main-question-block-content">
            <div class="main-question-block-list">
                    <div class="question-block">
                        <p>Как оплачивать аренду машино-места?</p>
                        <div class="question-button"><p v-if="faq[0]" @click="hideFAQ(0)">-</p><p v-else @click="showFAQ(0)">+</p></div>
                    </div>
                    <transition name="slide-fade">
                    <div class="answer-block" v-if="faq[0]">
                        <p>Оплата осуществляется помесячно авансом до 25-го числа месяца, предшествующего оплачиваемому. При заключении договора аренды Вы оплачиваете первый месяц аренды.</p>
                    </div>
                    </transition>

                    <div class="question-block">
                        <p>Возможно ли на арендуемое машино-место ставить разные машины?</p>
                        <div class="question-button"><p v-if="faq[1]" @click="hideFAQ(1)">-</p><p v-else @click="showFAQ(1)">+</p></div>
                    </div>
                    <transition name="slide-fade">
                    <div class="answer-block" v-if="faq[1]">
                        Ответ 2
                    </div>
                    </transition>

                    <div class="question-block">
                        <p>Какой минимальный срок аренды машино-места?</p>
                        <div class="question-button"><p v-if="faq[2]" @click="hideFAQ(2)">-</p><p v-else @click="showFAQ(2)">+</p></div>
                    </div>
                    <transition name="slide-fade">
                    <div class="answer-block" v-if="faq[2]">
                        Ответ 3
                    </div>
                    </transition>

                    <div class="question-block">
                        <p>Необходимо ли оплачивать коммунальные платежи при аренде машино-места?</p>
                        <div class="question-button"><p v-if="faq[3]" @click="hideFAQ(3)">-</p><p v-else @click="showFAQ(3)">+</p></div>
                    </div>
                    <transition name="slide-fade">
                    <div class="answer-block" v-if="faq[3]">
                        Ответ 4
                    </div>
                    </transition>

                    <div class="question-block">
                        <p>Можно ли купить машино-место в ипотеку?</p>
                        <div class="question-button"><p v-if="faq[4]" @click="hideFAQ(4)">-</p><p v-else @click="showFAQ(4)">+</p></div>
                    </div>
                    <transition name="slide-fade">
                    <div class="answer-block" v-if="faq[4]">
                        Ответ 5
                    </div>
                    </transition>

                    <div class="question-block">
                        <p>Почему широкие места стоят дороже?</p>
                        <div class="question-button"><p v-if="faq[5]" @click="hideFAQ(5)">-</p><p v-else @click="showFAQ(5)">+</p></div>
                    </div>
                    <transition name="slide-fade"> 
                    <div class="answer-block" v-if="faq[5]">
                        Стоимость комунальных услуг зависит от площади мм.
                        Чем больше мм, тем выше наши расходов. 
                        Дополнительные 500 руб - это компенсация более высоких комунальных затрат
                    </div>
                    </transition>
            </div>

            <div class="main-question-block-right">
                <h2>Наши преимущества</h2>

                <p><b>|</b> Официальный договор</p>
                <p><b>|</b> Pаботает с юр. лицами, принимаем оплату на р/с</p>
                <p><b>|</b> Любой срок аренды</p>
                <p><b>|</b> Возможность выкупа м/м</p>
                <p><b>|</b> Приоритетный переезд на другое освободившееся или новое м/м</p>
                <p><b>|</b> Акции для постоянных клиентов и гибкая система цен</p>
            </div>
        </div>
    </div>

    <a name="scheme"></a>
    <div class="main-parking-doc-block">
        <div class="main-parking-doc-block-header">
            <h2>Схема паркинга</h2>
            <div class="parking-doc-header-buttons">
                <p id="parking-doc-btn1" @click="switchParkingImg(1)" :class="{'parkingButtonActive': parkingImg == 1}">-1 этаж</p>
                <p id="parking-doc-btn2" @click="switchParkingImg(2)" :class="{'parkingButtonActive': parkingImg == 2}">-2 этаж</p>
            </div>
        </div>

        <a href="https://sf16.ru/parking1.jpg" target="_blank"><img src="../img/parking1.jpg" v-if="parkingImg == 1"></a>
        <a href="https://sf16.ru/parking2.jpg" target="_blank"><img src="../img/parking2.jpg" v-if="parkingImg == 2"></a>
    </div>
    </main>
</template>

<script>
import axios from 'axios'

export default{
    data(){
        return{
            faq: [],
            offer: {
                type: '',
                building: '',
                floor: '',
                size: '',
                section: '',
                title: '',
                phone: '',
                price: '',
                time: ''
            },
            parkingImg: 1
        }
    },

    methods:{
        showFAQ(index){
            this.faq[index] = true
        },

        hideFAQ(index){
            this.faq[index] = false
        },

        sendOffer(){
            let valid = true

            for(let item in this.offer){
                let val = this.offer[item]
                if(val == '') valid = false
            }

            if(valid){
                console.log(this.offer)
                axios.post('https://sf16.ru/send_offer.php', this.offer).then((response)=>{
                    console.log(response)
                    if(response.status ==200){
                        alert('Заявка отправлена!')
                        for(let item in this.offer){
                            this.offer[item] = ''
                        }
                    }
                    else{
                        alert('Ошибка, повторите попытку!')
                        for(let item in this.offer){
                            this.offer[item] = ''
                        }
                    }
                })
            }
            else{
                alert('Заполните корректно все поля')
            }
        },

        switchParkingImg(n){
            this.parkingImg = n
        }
    },

    beforeMount(){
        this.faq[0] = true
        for(let i=1; i<6; i++){
            this.faq[i] = false
        }
    }
}
</script>

<style lang="scss">
.header-main{
    display: flex;
    justify-content: space-between;
    margin-top: 135px;
    width: 100%;
    height: 100%;

    .header-right-block-mob{
        display: none;
    }

    .header-right-block{
        width: 60%;
        height: auto;
        background: url(../img/parking_main.jpg);
        background-size: cover;
        background-position: center;
    }

    .header-left-block{
        width: 45%;
        padding-bottom: 50px;
        h1{
            margin-top: 50px;
        }

        .header-left-block-buttons{
            display: flex;
            margin-top: 50px;

            button{
                margin-right: 16px;
            }
        }
    }
}

.main-rent-block{
    margin-top: 100px;

    .main-rent-block-buttons{
        margin-top: 50px;
        display: flex;
        justify-content: space-between;

        .rent-button{
            width: 30%;
            background: #ededed;
            border-bottom: 2px solid #153075;

            img{
                width: 100%;
                object-fit: cover;
                aspect-ratio: 16 / 9;
            }

            .rent-button-content{
                padding: 20px;

                p{
                    font-size: 14px;
                    margin-bottom: 10px;
                }

                button{
                    margin-top: 10px;
                }
            }
        }
    }
}

.main-rentout-block{
    margin-top: 100px;

    .main-rentout-block-content{
        display: flex;
        justify-content: space-between;
        margin-top: 50px;

        .main-rentout-block-img{
            width: 60%;
            height: auto;
            background: url(../img/rentout.webp);
            background-size: cover;
        }
    }
}

        .main-rentout-block-form{
            width: fit-content;
            font-size: 14px;
            padding-left: 10%;

            .form-item{
                display: flex;
                margin: 25px 0;
                p{
                    margin-right: 20px;
                }

                label{
                    margin-left: 3px;
                }

                #parking{
                    width: 60px;
                }

                #phone{
                    width: 150px;
                }

                #price{
                    width: 135px;
                }

                #time{
                    width: 100px;
                }

                button{
                    margin-top: 20px;
                    margin-left: auto;
                }

                input{
                    font-size: 14px;
                }

                select{
                    padding: 8px 0;
                    font-size: 14px;
                    border: none;
                    background-color: transparent;
                    outline: none;

                    option{
                        font-size: 14px;
                    }
                }
            }
        }

.main-question-block{
    margin-top: 100px;

    .main-question-block-content{
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        
        
        .main-question-block-list{
            width: 40%;
            padding-bottom: 30px;

            .question-block{
                font-size: 18px;
                display: flex;
                justify-content: space-between;
                margin-top: 30px;
                width: 90%;

                .question-button{
                    font-size: 30px;
                    line-height: 0.7;
                    cursor: pointer;
                }
            }

            .answer-block{
                width: 80%;
                color: #6d6d6d;
                margin-top: 10px;
                font-size: 14px;
            }
        }

        .main-question-block-right{
            width: 50%;
            height: fit-content;
            background: #ededed;
            padding: 30px 50px;

            h2{
                margin-bottom: 50px;
                margin-top: 20px;
            }

            p{
                margin-bottom: 20px;
                font-size: 14px;

                b{
                    color: #153075;
                }
            }
        }
    }
    
}

.main-parking-doc-block{
    margin-top: 100px;

    .main-parking-doc-block-header{
        display: flex;

        .parking-doc-header-buttons{
            display: flex;
            margin-left: 50px;
            cursor: pointer;

            p{
                margin-right: 18px;
                font-size: 18px;
                margin-top: auto;
                margin-bottom: auto;
                text-decoration: underline;
                color: #6d6d6d;
            }

            .parkingButtonActive{
                color: #000;
                text-decoration: none;
            }
        }
    }    

    img{
        width: 70%;
        margin-left: 15%;
        margin-right: 15%;
        margin-top: 50px;
    }
}

.main-rentout-block-img-mob{
    display: none;
}

@media screen and (max-width: 576px){
    .header-main{
        display: block;
        min-height: 80vh;

        .header-right-block-mob{
            display: block;
            width: 100%;

            img{
                width: 100%;
            }
        }

        .header-right-block{
            display: none;
        }

        .header-left-block{
            width: 100%;

            h1{
                margin-top: 20px;
                font-size: 30px;
            }

            .header-left-block-buttons{
                margin-top: 30px;
            }
        }
    }

    .main-rent-block{
        margin-top: 50px;

        .main-rent-block-buttons{
            display: block;

            .rent-button{
                width: 100%;
                margin-bottom: 50px;

                .rent-button-content{
                    p{
                        font-size: 16px;
                    }
                    
                }
            }
        }
    }

    .main-rentout-block{
        .main-rentout-block-content{
            display: block;
            margin-top: 30px;

            .main-rentout-block-img{
                display: none;
            }

            .main-rentout-block-img-mob{
                display: block;

                img{
                    width: 100%;
                }
            }
        }
    }

    .main-rentout-block-form{
        padding-left: 0;
        font-size: 16px;

        .form-item{
            select, input{
                font-size: 16px;
            }
        }
    }

    .main-question-block{
        .main-question-block-content{
            display: block;
            margin-top: 30px;

            .main-question-block-list{
                width: 100%;

                .question-block{
                    font-size: 20px;
                    justify-content: space-between;
                    width: 100%;

                    .question-button{
                        padding-left: 10%;
                        font-size: 35px;
                    }
                }

                .answer-block{
                    font-size: 16px;
                }
            }

            .main-question-block-right{
                width: 100%;
                margin-top: 30px;
                padding: 20px 30px;

                h2{
                    font-size: 24px;
                    margin-bottom: 30px;
                    margin-top: 10px;
                }

                p{
                    font-size: 16px;
                }
            }
        }
    }

    .main-parking-doc-block{
        .main-parking-doc-block-header{
            display: block;

            .parking-doc-header-buttons{
                margin-left: 0;
                margin-top: 20px;
                
            }
        }
    }

    
}
</style>
