<template>
    <div class="img-wrapper-bg"></div>
    <div class="img-wrapper">
        <img :src="imgSrc" alt="">
    </div>
        
</template>

<script>
export default{
    props: {
        imgSrc: String
    }
}
</script>

<style lang="scss" scoped>

.img-wrapper-bg{
    position: fixed;
    background-color: #153075;
    opacity: 0.5;
    display: flex;
    width: 100vw;
    min-height: 100vh;
    top: 0;
}

.img-wrapper{
    width: 100%;
    min-height: 100vh;
    display: flex;
    position: fixed;
    top: 0;
    img{
        width: 80%;
        margin: auto;
    }
}

</style>