<template>
    <footer>
        <a name="contacts"></a>
        <div class="footer-content-block">
            <div class="footer-content-left-block">
                <div class="footer-logo">
                    <img src="../img/logo.png" alt="">
                    <div class="footer-logo-text">
                        Агенство <br>
                        недвижимости <br>
                        ODream</div>
                </div>

                <div class="footer-links">
                    <a href="/оферта.pdf" target="_blank">Договор оферты</a>
                    <a href="/politics.pdf" target="_blank">Политика конфиденциальности и обратотки данных</a>
                </div>
            </div>

            <div class="footer-info-block">
                <p>Информация</p>
                <a href="/document_2.pdf" target="_blank">Договор купли-продажи м/м</a>
                <a href="/document_1.pdf" target="_blank">Договор аренды м/м</a>
                <a href="/document_3.pdf" target="_blank">Договор субаренды м/м</a>
                <a href="/оферта.pdf" target="_blank">Публичная оферта</a>
            </div>

            <div class="footer-content-right-block">
                <div class="footer-phones">
                    <p>Номера телефонов компании:</p>
                    <a href="tel:79104929145">+7 (910) 492-91-45</a>
                    <a href="tel:79137705961">+7 (913) 770-59-61</a>
                </div>

                <div class="footer-socials">
                    <a href="https://wa.me/message/CZBZBN7HVPHJB1"><img src="../img/whatsapp_icon.svg" alt=""> WhatsApp</a>
                </div>

                <div class="footer-mail">
                    <p>Электронная почта:</p>
                    <a href="mailto:odream0011@gmail.com">parking.sf16@gmail.com</a>
                </div>
            </div>
        </div>
    </footer>
</template>

<style lang="scss" scoped>
footer{
    .footer-content-block{
        margin-top: 50px;
        background-color: #ededed;
        display: flex;
        justify-content: space-between;
        padding: 45px 10%;
        border-top: 2px solid #153075;

        .footer-content-left-block{
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .footer-logo{
                display: flex;
                margin-bottom: 10px;

                img{
                    width: 80px;
                    height: 80px;
                }

                .footer-logo-text{
                    font-size: 14px;
                    line-height: 1.7;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-left: 15px;
                }
            }

            .footer-links{

                a{
                    display: block;
                    text-decoration: underline;
                    margin-top: 10px;
                    font-size: 14px;
                }
            }
        }

        .footer-info-block{
            font-size: 14px;
            text-align: center;

            p{
                margin-bottom: 20px;
                font-size: 16px;
            }

            a{
                display: block;
                margin-top: 15px;
                text-decoration: underline;
            }
        }

        .footer-content-right-block{
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p{
                font-size: 14px;
                margin-bottom: 5px;
            }

            .footer-phones{
                font-size: 16px;
                a{
                    display: block;
                    line-height: 1.2;
                }
            }

            .footer-socials{
                a{
                    font-size: 16px;
                    
                    img{
                        width: 20px;
                        height: auto;
                    }
                }
            }

            .footer-mail{
                a{
                    font-size: 16px;
                    line-height: 1.2;
                }
            }
        }
    }
}

@media screen and (max-width: 576px){
    footer{
        .footer-content-block{
            display: block;
        }

        .footer-info-block{
            margin: 50px 0;
        }

        .footer-logo{
            margin-bottom: 0 !important;
        }

        .footer-logo-text{
            margin-left: 10px !important;
        }
    }
}

</style>