<template>
    <div class="modal-img" @click="hideImg()">
        <modal-img :imgSrc="openImgSrc" v-if="openImgSrc!==''"/>
    </div>

    <div class="lk-signin-block" v-if="showEditProfile">
        <div class="lk-signin-wrapper"></div>
        <div class="lk-signin-popup">
                <h2>Изменить данные</h2>

                <button class="hide-form-button" @click="showEditProfile = false">&#10006;</button>

                <div class="lk-signin-popup-form">
                    <div class="form-item">
                        <p>ФИО</p>
                        <input type="text" id="signup-name" v-model="user_info.name">
                    </div>

                    <div class="form-item">
                        <p>E-mail</p>
                        <input type="text" id="signup-mail" v-model="user_info.mail">
                    </div>

                    <div class="form-item">
                        <p>Телефон</p>
                        <input type="text" id="signup-phone" v-model="user_info.phone">
                    </div>

                    <div class="form-item">
                        <p>Пароль</p>
                        <input type="password" id="signup-password" v-model="user_info.password">
                    </div>

                    <div class="form-enter-buttons">
                        <button class="form-reg-btn" @click="editData">Сменить данные</button>
                        
                    </div>
                    
                </div>
            </div>
        </div>

        <div class="lk-signin-block" v-if="showLoadPassport">
            <div class="lk-signin-wrapper"></div>
            <div class="lk-signin-popup">
                <h2>Загрузить паспорт</h2>

                <button class="hide-form-button" @click="showLoadPassport = false">&#10006;</button>

                <div class="lk-signin-popup-form">

                    <div class="form-item">
                        <!-- <p>Прикрепить скан</p> -->
                        <input type="file" id="images" ref="images" class="form-item-file" @change="handleImageUpload()" multiple="multiple">
                    </div>

                    <div class="form-enter-buttons">
                        <button class="form-reg-btn" @click="loadPassport">Загрузить</button>
                        
                    </div>
                    
                </div>
            </div>
        </div>

        <div class="lk-signin-block" v-if="showLoadDocs">
            <div class="lk-signin-wrapper"></div>
            <div class="lk-signin-popup load-docs-popup">
                <h2>Отправить документы</h2>

                <button class="hide-form-button" @click="showLoadDocs = false">&#10006;</button>

                <p>Отправить договор аренды м/м для проверки можно на почту odream0011@gmail.com</p>
                <p>После отправки мы в ближайшее время свяжемся с вами и обновим статус в личном кабинете</p>
                    
            </div>
        </div>

        <div class="lk-signin-block" v-if="showPay">
            <div class="lk-signin-wrapper"></div>
            <div class="lk-signin-popup load-docs-popup">
                <h2>Отправить документы</h2>

                <button class="hide-form-button" @click="showPay = false">&#10006;</button>

                <p>Оплатить можно, связавшись с нами по почте odream0011@gmail.com или по номерам телефона, которые указаны в разделе Контакты</p>
                <!-- <p>После отправки мы в ближайшее время свяжемся с вами и обновим статус в личном кабинете</p> -->
                    
            </div>
        </div>

    <main>
        <div class="header">
            <h1>Личный кабинет</h1>
        </div>
        <div class="lk-block">

            <div class="lk-block-profile">
                <h2>Профиль</h2>
                <div class="profile-field">
                    <p>ФИО:</p>
                    <p>{{ user_info.name }}</p>
                </div>
                <div class="profile-field">
                    <p>Номер телефона:</p>
                    <p>{{ user_info.phone }}</p>
                </div>
                <div class="profile-field">
                    <p>E-Mail:</p>
                    <p>{{ user_info.mail }}</p>
                </div>
                <div class="profile-field" style="display: block;">
                    <p>Паспорт:</p>
                    <div v-if="user_info.passport !== 'null'">
                        <a :href="'https://sf16.ru/'+item" target="_blank" v-for="(item, index) in JSON.parse(user_info.passport)" :key="index">{{ item.slice(item.lastIndexOf('/')+1) }}</a>
                    </div>
                    <a @click="showLoadPassport = true">Загрузить скан паспорта</a>
                </div>

                <a @click="showEditProfile = true">Изменить данные</a>

                <div class="profile-field" style="margin-bottom: 0;">
                     <button @click="exitUser">Выйти</button>
                </div>
               
            </div>

            <div class="lk-block-parking">
                <div class="booking parking-list-block">
                    <h2>Забронированные</h2>
                    <p v-if="booked.length == 0" style="text-align: center;">Не найдено</p>
                    
                    <div class="parking-list-table" v-else>
                        <ul class="parking-list-table-line-header">
                            <li>Номер м/м</li>
                            <li>Размер</li>
                            <li>Этаж</li>
                            <li>Секция</li>
                            <li>Стоимость</li>
                        </ul>

                        <ul class="parking-list-table-line" v-for="(item, index) in booked" :key="index">
                            <div class="line-content">
                                <li>{{ item.title }}</li>
                                <li>{{ item.size }} м&#178;</li>
                                <li>{{ item.floor }} этаж</li> 
                                <li>{{ item.section }} секция</li>
                                <li>{{ item.price }} руб./мес.</li>
                                <li class="line-content-img"><img :src="'https://sf16.ru/'+item.img" alt="" @click="openImg('https://sf16.ru/'+item.img)"></li>
                            </div>
                            <div class="line-buttons">
                                <li class="line-content-img-mob"><img :src="'https://sf16.ru/'+item.img" alt="" @click="openImg('https://sf16.ru/'+item.img)"></li>
                                <li><a @click="cancelBooking(item.title)">отменить</a></li>
                                <li><button @click="showLoadDocs = true">Отправить документы</button></li>
                            </div>
                        </ul>
                        
                    </div>

                </div>

                <div class="rent parking-list-block">
                    <h2>Арендованные</h2>
                    <p v-if="rented.length == 0" style="text-align: center;">Не найдено</p>
                    
                    <div class="parking-list-table" v-else>
                        <ul class="parking-list-table-line-header">
                            <li>Номер м/м</li>
                            <li>Размер</li>
                            <li>Этаж</li>
                            <li>Секция</li>
                            <li>Стоимость</li>
                        </ul>

                        <ul class="parking-list-table-line" v-for="(item, index) in rented" :key="index">
                            <div class="line-content">
                                <li>{{ item.title }}</li>
                                <li>{{ item.size }} м&#178;</li>
                                <li>{{ item.floor }} этаж</li> 
                                <li>{{ item.section }} секция</li>
                                <li>{{ item.price }} руб./мес.</li>
                                <li class="line-content-img"><img :src="'https://sf16.ru/'+item.img" alt="" @click="openImg('https://sf16.ru/'+item.img)"></li>
                            </div>
                            <div class="line-buttons">
                                <li class="line-content-img-mob"><img :src="'https://sf16.ru/'+item.img" alt="" @click="openImg('https://sf16.ru/'+item.img)"></li>
                                <li><button @click="showPay = true">Оплатить аренду</button></li>
                            </div>
                        </ul>
                        
                    </div>

                </div>
            </div>

        </div>
    </main>
</template>

<script>
import axios from 'axios'
import ModalImg from '../components/ModalImg.vue'

export default {

    components:{
        ModalImg
    },

  data(){
    return{
        user_info: this.$store.state.user_info,
        showEditProfile: false,
        showLoadPassport: false,
        showLoadDocs: false,
        showPay: false,
        images: '',
        booked: [],
        rented: [],
        openImgSrc: ''
    }
  },
  
  methods: {
    exitUser(){
        this.$store.commit('exitUser')
        window.location.href = '/'
    },

    editData(){
        let valid = true
        for(let data in this.user_info){
            if(this.user_info[data]==''){
                valid = false
            }
        }

        if(valid){
            axios.post('https://sf16.ru/edit_profile.php', this.user_info).then((response)=>{
                console.log(response)
                this.$store.commit('authUser', response.data)
                window.location.reload()
            })
        }
        else{
            alert('Заполните корректно все поля!')
        }
    },

    handleImageUpload(){
        this.images = this.$refs.images.files;
    },

    loadPassport(){
        let formData = new FormData()

            for( let i = 0; i < this.images.length; i++ ){
                let image = this.images[i]
                formData.append('images[' + i + ']', image)
            }
            formData.append('folder', this.user_info.id)

            axios.post( 'https://sf16.ru/upload_passport.php',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then((response)=>{
                console.log(response)

                if(response.data == 'Файлы загружены'){
                    alert('Успешно')
                    this.loadData()
                    window.location.reload()
                }
                else{
                    alert('Произошла ошибка, повторите еще раз')
                }
            })
    },

    loadData(){
        axios.post('https://sf16.ru/signIn.php', this.user_info).then((response)=>{
                if(response.data !== 0){
                    console.log(response.data)
                    this.$store.commit('authUser', response.data)
                }
            })
    },

    loadBookedPlaces(){
        axios.post('https://sf16.ru/get_user_booked.php', {id: this.user_info.id}).then((response)=>{
            this.booked = response.data
        })
    },

    loadRentedPlaces(){
        axios.post('https://sf16.ru/get_user_rented.php', {id: this.user_info.id}).then((response)=>{
            this.rented = response.data
        })
    },

    openImg(img){
        this.openImgSrc = img
    },

    hideImg(){
        this.openImgSrc = ''
    },

    cancelBooking(title){
        axios.post('https://sf16.ru/cancel_book_user_place.php', {place_title: title}).then((response)=>{
            console.log(response)
            this.loadBookedPlaces()
        })
    }
  },

  beforeMount(){
    console.log(this.user_info)
    this.loadData()
    this.loadBookedPlaces()
    this.loadRentedPlaces()
  }
}
</script>

<style lang="scss" scoped>
    .header{
        margin-top: 150px;
        justify-content: center;
    }

    .lk-block{
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        margin-top: 100px;

        .lk-block-profile{
            background: #ededed;
            padding: 30px 40px;
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
            border-radius: 10px;

            a{
                font-size: 14px;
                display: block;
                margin: 5px 0;
            }

            h2{
                margin-bottom: 30px;
                text-align: center;
            }

            button{
                margin: 0 auto;
                margin-top: 10px;
            }

            .profile-field{
                font-size: 16px;
                display: flex;
                margin: 15px 0;

                p:first-child{
                    font-weight: 600;
                    margin-right: 10px;
                }
            }
        }

        .lk-block-parking{
            margin-top: 50px;

            h2{
                margin-bottom: 50px;
                text-align: center;
            }

            .booking, .rent{
                padding: 30px;
                margin-bottom: 30px;
            }
        }
    }

.load-docs-popup{
    p{
        margin-top: 20px;
    }
}

@media screen and (max-width: 576px) {
    .lk-block{
        margin-top: 30px;

        .lk-block-parking{
            margin-top: 0;

            .booking, .rent{
                padding: 0;
            }
        }
    }
}
</style>