<template>

    <admin-panel />
    
</template>

<script>
import AdminPanel from '@/components/AdminPanel.vue';

export default{
    components: {
        AdminPanel
    }
}
</script>