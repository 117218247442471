import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '../pages/MainPage.vue'
import RentPage from '../pages/RentPage.vue'
import BuyPage from '../pages/BuyPage.vue'
import LK from '../pages/LK.vue'
import AdminPage from '../pages/AdminPage.vue'

const routes = [
    {
        path: '/',
        name: 'main',
        component: MainPage
    },
    {
        path: '/rent',
        name: 'rent',
        component: RentPage,
        props: route => ({ building: route.query.k })
    },
    {
        path: '/lk',
        name: 'lk',
        component: LK
    },
    {
        path: '/admin',
        name: 'admin',
        component: AdminPage
    },
    {
        path: '/buy',
        name: 'buy',
        component: BuyPage
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })
  
  export default router