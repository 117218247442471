<template>
    <main>
        <div class="admin-panel">
            <h1>Панель администратора</h1>

            <div class="admin-panel-content">
                <div class="admin-panel-add-place">
                    <h2>Добавить место</h2>

                    <div class="main-rentout-block-form">

                        <div class="form-item">
                            <select class="form-select" v-model="action">
                                <option value="" selected disabled>Выберите цель</option>
                                <option value="rent">Аренда</option>
                                <option value="sell">Продажа</option>
                            </select>
                        </div>


                        <div class="form-item">
                            <select class="form-select" v-model="building">
                                <option value="" selected disabled>Выберите корпус</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                            </select>
                        </div>

                        <div class="form-item">
                            <select class="form-select" v-model="floor">
                                <option value="" selected disabled>Выберите этаж</option>
                                <option>-1</option>
                                <option>-2</option>
                            </select>
                        </div>

                        <div class="form-item">
                            <select class="form-select" v-model="size">
                                <option value="" selected disabled>Выберите размер</option>
                                <option>13,3</option>
                                <option>14,8</option>
                            </select>
                        </div>

                        <div class="form-item">
                            <select class="form-select" v-model="section">
                                <option disabled selected value="">Выбрать секцию</option>
                                <option value="all">Все секции</option>
                                <option v-for="section in 10" :value="section" :key="section">Секция {{ section }}</option>
                            </select>
                        </div>

                        <div class="form-item">
                        <p>Введите номер места:</p>
                        <input type="text" id="parking" v-model="title">
                        </div>

                        <div class="form-item">
                            <p>Цена сдачи/продажи:</p>
                            <input type="text" id="price" v-model="price">
                        </div>

                        <div class="form-item" style="margin-top: 30px;">
                            <input type="file" id="image" ref="image" class="form-item-file" @change="handleImageUpload()">
                        </div>

                        <div class="form-item">
                        <button @click="createRentPlace()">Создать место</button>
                        </div>
                    </div>
                </div>

                <div class="admin-panel-delete-place">
                    <h2>Удалить место аренда</h2>

                    <select v-model="delete_place_rent">
                        <option value="" selected disabled>Выбрать место</option>
                        <option :value="item.id" v-for="(item, index) in places_rent" :key="index">{{ item.title }}</option>
                    </select>

                    <button @click="deletePlace('rent', delete_place_rent)">Удалить</button>

                    <h2>Удалить место покупка</h2>

                    <select v-model="delete_place_buy">
                        <option value="" selected disabled>Выбрать место</option>
                        <option :value="item.id" v-for="(item, index) in places_buy" :key="index">{{ item.title }}</option>
                    </select>

                    <button @click="deletePlace('buy', delete_place_buy)">Удалить</button>
                </div>
            </div>
           
        </div>
    </main>
   
</template>

<script>
import axios from 'axios'

export default{
    data(){
        return{
            action: '',
            building: '',
            floor: '',
            title: '',
            section: '',
            price: '',
            image: '',
            size: '',
            places_rent: [],
            places_buy: [],
            delete_place_rent: '',
            delete_place_buy: ''
        }
    },

    methods: {
        handleImageUpload(){
            this.image = this.$refs.image.files[0];
        },

        createRentPlace(){
        let data = {
            action: this.action,
            title: this.title,
            size: this.size,
            floor: this.floor,
            section: this.section,
            price: this.price,
            img: 'parking_images/' + this.image.name,
            building: this.building
        }

        if(this.image !== ''){
            let image_file = new FormData()

            image_file.append('image_file', this.image)
            image_file.append('folder', 'parking_images')

            axios.post('https://sf16.ru/upload_place_image.php',
                image_file,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                    }).then((response)=>{
                        console.log(response)
                        axios.post('https://sf16.ru/create_rent_place.php', data).then((response)=>{
                            console.log(response)
                            alert('Успешно')
                            window.location.reload()
                        })
                    })
                }

        else{
            axios.post('https://sf16.ru/create_rent_place.php', data).then((response)=>{
            console.log(response)
            alert('Успешно')
            window.location.reload()
        })
        }

    },

    loadData(){
        axios.post('https://sf16.ru/get_rent_places.php').then((response)=>{
            console.log(response)
            this.places_rent = response.data
        })

        axios.post('https://sf16.ru/get_buy_places.php',).then((response)=>{
            console.log(response)
            this.places_buy = response.data
        })
    },

    deletePlace(type, id){
        axios.post('https://sf16.ru/delete_place.php', {'type': type, 'id': id}).then((response)=>{
            console.log(response)
            alert('Успешно')
            window.location.reload()
        })
    }

    },

    beforeMount(){
        this.loadData()
    }
}
</script>

<style lang="scss">
.admin-panel{
    h1{
        margin-top: 135px;
        text-align: center;
    }

    h2{
        margin-top: 100px;
    }

    .main-rentout-block-form{
        padding: 30px;
    }
}

.admin-panel-content{
    display: flex;
    justify-content: space-around;

    select{
        height: fit-content;
        outline: none;
        border: none;
        background: #fff;
        font-size: 16px;
    }

    .admin-panel-delete-place{

        h2{
            margin-bottom: 30px;
        }

        button{
            margin-left: 30px;
        }

        select{
            margin-left: 30px;
        }
    }
}

@media screen and (max-width: 576px){
    .admin-panel-content{
        display: block;
    }
}

</style>