<template>
    
    <div class="modal-img" @click="hideImg()">
        <modal-img :imgSrc="openImgSrc" v-if="openImgSrc!==''"/>
    </div>

    <div class="lk-signin-block" v-if="showBuyOffer">
        <div class="lk-signin-wrapper"></div>
        <div class="lk-signin-popup">
                <h2>Отправить заявку</h2>

                <button class="hide-form-button" @click="showBuyOffer = false">&#10006;</button>

                <div class="lk-signin-popup-form">
                    <div class="form-item">
                        <p>ФИО</p>
                        <input type="text" id="signup-name" v-model="buyOffer.name">
                    </div>

                    <div class="form-item">
                        <p>E-mail</p>
                        <input type="text" id="signup-mail" v-model="buyOffer.mail">
                    </div>

                    <div class="form-item">
                        <p>Телефон</p>
                        <input type="text" id="signup-phone" v-model="buyOffer.phone">
                    </div>

                    <div class="form-enter-buttons">
                        <button class="form-reg-btn" @click="sendOffer()">Отправить</button>
                        
                    </div>
                    
                </div>
            </div>
        </div>

    <main>
        <div class="header">
        <div class="header-left-block">
            <h1>
                Купить паркинг в ЖК Серебряный Фонтан
            </h1>
            <div class="header-left-block-filter">
                 
                 <div class="filter-line-1">
                     <div class="filter-item">
                         <p>Стоимость</p>
                         <div class="filter-price">
                             <label>от</label>
                             <input type="text" v-model="filter.priceStart" >
                             <label>руб.</label>
                             <label>до</label> 
                             <input type="text" v-model="filter.priceEnd">
                             <label>руб.</label>
                         </div>
                     </div>
 
                     <div class="filter-item">
                         <p>Выберите размер м/м:</p>
                         <div class="filter-size">
                             <input type="radio" name="size" id="size1" value="13,3" v-model="filter.size" >
                             <label for="size1">13,3</label>
                             <br>
                             <input type="radio" name="size" id="size2" value="14,8" v-model="filter.size">
                             <label for="size2">14,8</label>
                         </div>
                     </div>
 
                     <div class="filter-item">
                         <p>Выберите этаж:</p>
                         <div class="filter-floor">
                             <input type="radio" name="floor" id="floor1" value="-1" v-model="filter.floor">
                             <label for="floor1">-1</label>
                             <br>
                             <input type="radio" name="floor" id="floor2" value="-2" v-model="filter.floor">
                             <label for="floor2">-2</label>
                         </div>
                     </div>
 
                     <div class="filter-item">
                         <select name="line" class="filter-section" v-model="filter.section">
                             <option disabled selected value="">Выбрать секцию</option>
                             <option v-for="section in 11" :value="section" :key="section">Секция {{ section }}</option>
                         </select>
                     </div>
                     <div class="filter-item">
                         <select name="line" class="filter-section" v-model="filter.building">
                             <option disabled selected value="">Выбрать корпус</option>
                             <option :value="2">К2</option>
                             <option :value="3">К3</option>
                             <option :value="4">К4</option>
                         </select>
                     </div>
                 </div>
                 
                 <div class="filter-footer">
                     <a @click="resetFilter()">сбросить</a>
                     <button @click="applyFilter()">Показать</button>
                 </div>
 
             </div>
        </div>
    </div>

    <div class="parking-list-block">
        <p>Найдено {{ parkingLots.length }} м/м</p>

        <div class="parking-list-table">
            <ul class="parking-list-table-line-header">
                <li>Номер м/м</li>
                <li>Размер</li>
                <li>Этаж</li>
                <!-- <li>Секция</li> -->
                <li>Корпус</li>
                <li>Стоимость</li>
            </ul>
            <ul class="parking-list-table-line" v-for="(item, index) in parkingLots" :key="index">
                <div class="line-content">
                    <li>{{ item.title }}</li>
                    <li>{{ item.size }} м&#178;</li>
                    <li>{{ item.floor }} этаж</li> 
                    <!-- <li>{{ item.section }} секция</li> -->
                    <li>К{{ item.building }}</li>
                    <li>{{ item.price }} руб.</li>
                    
                    <li class="line-content-img"><img :src="'https://sf16.ru/'+item.img" alt="" @click="openImg('https://sf16.ru/'+item.img)"></li>
                </div>
                <div class="line-buttons">
                    <li class="line-content-img-mob"><img :src="'https://sf16.ru/'+item.img" alt="" @click="openImg('https://sf16.ru/'+item.img)"></li>
                    <li><a :href="'https://sf16.ru/parking' + item.floor[1] + '.jpg'" target="_blank">план этажа</a></li>
                    <li><button @click="showBuyOfferPopup(item.title)">Отправить заявку</button></li>
                </div>
            </ul>
            
        </div>
    </div>
    </main>
</template>

<script>
import axios from 'axios'
import ModalImg from '../components/ModalImg.vue'

export default {
    components:{
        ModalImg
    },

  data() {
    return {
        parkingLots: [],
        filter: {
            priceStart: '',
            priceEnd: '',
            size: '',
            floor: '',
            section: '',
            building: ''
        },
        openImgSrc: '',
        showBuyOffer: false,
        buyOffer: {
            name: '',
            mail: '',
            phone: '',
            title: ''
        }
    }
  },

  methods: {
    loadData(){
        axios.post('https://sf16.ru/get_buy_places.php',).then((response)=>{
            console.log(response)
            this.parkingLots = response.data
        })
    },

    applyFilter(){
         console.log(this.filter)
         axios.post('https://sf16.ru/filter_buy_places.php', this.filter).then((response)=>{
            console.log(response)
            this.parkingLots = response.data
         })
    },

    resetFilter(){
        this.filter = {
            priceStart: '',
            priceEnd: '',
            size: '',
            floor: '',
            section: '',
            building: ''
        }
    },

    openImg(img){
        this.openImgSrc = img
    },

    hideImg(){
        this.openImgSrc = ''
    },

    showBuyOfferPopup(title){
        this.showBuyOffer = true;
        this.buyOffer.title = title
    },

    sendOffer(){
        let valid = true

        for(let key in this.buyOffer){
            if(this.buyOffer[key] == ''){
                valid = false
            }
        }

        if(valid){
            console.log(this.buyOffer)
            axios.post('https://sf16.ru/send_offer_buy.php', this.buyOffer).then((response)=>{
                if(response.status == 200){
                    alert('Заявка отправлена')
                    for(let key in this.buyOffer){
                        this.buyOffer[key] = ''
                    }
                    this.showBuyOffer = false
                }
                else{
                    alert('Произошла ошибка, повторите попытку')
                }
            })
        }
        else{
            alert('Заполните все поля')
        }
    }
  },
  
  beforeMount () {

    this.loadData()

  }
}
</script>

<style lang="scss" scoped>
.header{
    display: flex;
    justify-content: space-between;
    margin-top: 135px;
    width: 100%;
    height: 100%;

    .header-left-block{
        width: 100%;
        padding-bottom: 40px;
        h1{
            margin-top: 20px;
            text-align: center;
        }

        .header-left-block-filter{
            width: 100%;
        }

        .filter-header{
            margin-top: 50px;

            p{
                font-size: 18px;
                margin-left: 50px;
            }
        }

        .filter-line-1{
            display: flex;
            justify-content: space-around;
            margin-top: 50px;
        }

        .filter-item{
            display: flex;
            margin-top: 20px;
            font-size: 14px;

            p{
                margin-right: 10px;
            }

            input[type="radio"]{
                margin-right: 5px;
            }

            .filter-section{
                height: fit-content;
                outline: none;
                border: none;
                background: #fff;
                font-size: 14px;
            }

            .filter-price{
                display: flex;
                input{
                    margin: 0 5px 0 10px;
                    width: 70px;
                    font-size: 14px;
                    height: 18px;
                }

                label:nth-child(4){
                    margin-left: 20px;
                }
            }

            .filter-size, .filter-floor{
                line-height: 1.5;
            }

        }

        .filter-footer{
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;

            a{
                font-size: 12px;
                color: #6d6d6d;
                text-decoration: underline;
                margin-right: 20px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }
}

.parking-list-block{
    margin-top: 50px;
    min-height: 30vh;

    p{
        font-size: 14px;
        color: #6d6d6d;
    }

    .parking-list-table{
        width: 100%;
        margin-top: 30px;

        ul{
            display: flex;
            list-style-type: none;

            li{
                
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        .parking-list-table-line-header{

            li{
                color: #6d6d6d;
                width: 10%;
                font-size: 12px;
            }
        }

        .parking-list-table-line{
            justify-content: space-between;
            height: 70px;
            border-bottom: 1px solid #6d6d6d;

            li{
                font-size: 14px;
            }

            div{
                display: flex;
            }
            .line-content{
                width: 60%;
                
                li{
                    width: 20%;
                }

                .line-content-img img{
                    height: 50px;
                    width: auto;
                    cursor: pointer;
                }
            }

            .line-buttons{
                width: auto;

                a{
                    font-size: 12px;
                    color: #6d6d6d;
                    text-decoration: underline;
                    margin-right: 18px;
                }
            }

            
            
        }
    }
}

@media screen and (max-width: 576px) {
    .header{
        .header-left-block{
            h1{
                margin-top: 0;
            }

            .filter-line-1{
                display: block;

                .filter-item{
                    margin-bottom: 50px;
                }
            }
        }
    }
}

</style>