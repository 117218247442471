<template>
    
    <div class="lk-signin-block" v-if="showlk">
        <div class="lk-signin-wrapper"></div>

        <div class="lk-signin-popup" v-if="popupSignin">
            <h2>Вход в личный кабинет</h2>

            <button class="hide-form-button" @click="$emit('hidePopUp')">&#10006;</button>

            <div class="lk-signin-popup-form">
                <div class="form-item">
                    <p>E-mail</p>
                    <input type="text" id="signin-mail" v-model="signInInfo.mail">
                </div>

                <div class="form-item">
                    <p>Пароль</p>
                    <input type="password" id="signin-password" v-model="signInInfo.password">
                </div>

                <div class="form-enter-buttons">
                    <button class="form-enter-btn" @click="signIn()">Войти</button>
                    <a @click="showResetPassword">Забыли пароль?</a>

                    <div class="form-enter-buttons-singup">
                        <p>Еще нет аккаунта?</p>
                        <a @click="showSignUp">Зарегистрируйтесь</a>
                    </div>
                    
                </div>
                
            </div>
        </div>

        <div class="lk-signin-popup" v-if="popupResetPassword">
            <h2>Восстановить пароль</h2>

            <button class="hide-form-button" @click="$emit('hidePopUp')">&#10006;</button>

            <div class="lk-signin-popup-form">
                <div class="form-item">
                    <p>E-mail</p>
                    <input type="text" id="signin-mail" v-model="resetPasswordInfo.mail">
                </div>

                <div class="form-enter-buttons">
                    <button class="form-enter-btn" @click="resetPassword()">Сбросить пароль</button>

                    <div class="form-enter-buttons-singup">
                        <p>Уже есть аккаунт?</p>
                        <a @click="showSignIn">Войдите</a>
                    </div>
                    
                </div>
                
            </div>
        </div>

        <div class="lk-signin-popup" v-if="popupSignup">
            <h2>Регистрация</h2>

            <button class="hide-form-button" @click="$emit('hidePopUp')">&#10006;</button>

            <div class="lk-signin-popup-form">
                <div class="form-item">
                    <p>ФИО</p>
                    <input type="text" id="signup-name" v-model="signUpInfo.name">
                </div>

                <div class="form-item">
                    <p>E-mail</p>
                    <input type="text" id="signup-mail" v-model="signUpInfo.mail">
                </div>

                <div class="form-item">
                    <p>Телефон</p>
                    <input type="text" id="signup-phone" v-model="signUpInfo.phone">
                </div>

                <div class="form-item">
                    <p>Пароль</p>
                    <input type="password" id="signup-password" v-model="signUpInfo.password">
                </div>

                <div class="form-enter-buttons">
                    <button class="form-reg-btn" @click="signUp()">Зарегистрироваться</button>

                    <div class="form-enter-buttons-singup">
                        <p>Уже есть аккаунт?</p>
                        <a @click="showSignIn">Войдите</a>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>



    
</template>

<script>
import axios from 'axios'

export default{
    props: ['showlk'],
    emits: ['hidePopUp'],

    data() {
        return{
            popupSignin: true,
            popupSignup: false,
            popupResetPassword: false,
            signInInfo: {
                mail: '',
                password: ''
            },
            signUpInfo : {
                name: '',
                mail: '',
                phone: '',
                password: ''
            },
            resetPasswordInfo: {
                mail: ''
            }
        }
    },

    methods: {
        showSignUp(){
            this.popupSignin = false
            this.popupResetPassword = false
            this.popupSignup = true
        },

        showSignIn(){
            this.popupSignin = true
            this.popupResetPassword = false
            this.popupSignup = false
        },

        showResetPassword(){
            this.popupSignin = false
            this.popupResetPassword = true
            this.popupSignup = false
        },

        signIn(){
            axios.post('https://sf16.ru/signIn.php', this.signInInfo).then((response)=>{
                if(response.data !== 0){
                    console.log(response.data)
                    this.$store.commit('authUser', response.data)
                    window.location.href = '/lk'
                }
                else{
                    alert('Неправильный e-mail или пароль!')
                }
            })
        },

        signUp(){
            let valid = true

            for(let key in this.signUpInfo){
                if(this.signUpInfo[key] == ''){
                    valid = false
                }
            }

            if(valid == true){
                axios.post('https://sf16.ru/check_signUp_info.php', this.signUpInfo).then((response)=>{
                    if(response.data == 1){
                        axios.post('https://sf16.ru/signUp.php', this.signUpInfo).then((response)=>{
                            console.log(response.data)
                            this.$store.commit('authUser', response.data)
                            window.location.href = '/lk'
                        })
                    }
                    else{
                        alert(response.data)
                    }
                })
            }
            else{
                alert('Заполните все поля!')
            }
        },

        resetPassword(){
            axios.post('https://sf16.ru/resetPassword.php', this.resetPasswordInfo).then((response)=>{
                alert(response.data)
                this.resetPasswordInfo.mail = ''
                this.showSignIn()
            })
        }
    }
}
</script>
<style lang="scss">
.lk-signin-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #153075;
    opacity: 0.5;
}

.lk-signin-popup{
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 20%;
    left: 35%;
    right: 30%;
    width: 30%;
    background-color: #ededed;
    padding: 40px;
    border-radius: 10px;

    .hide-form-button{
        background: transparent;
        font-size: 25px;
        width: 50px;
        height: 50px;
        position: absolute;
        right: 15px;
        top: 0;
        color: #000;
        opacity: 0.7;
    }

    .lk-signin-popup-form{
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        .form-item{
            display: flex;
            margin: 20px 0;

            p{
                margin-right: 20px;
                font-size: 14px;
            }

            input{
                font-size: 14px;
            }
        }

        .form-enter-buttons{
            display: flex;
            margin-top: 30px;
            flex-direction: column;

            a{
                font-size: 12px;
                opacity: 0.7;
                text-decoration: underline;
                margin-left: auto;
                margin-right: auto;
                margin-top: 10px;
            }

            button{
                margin-left: auto;
                margin-right: auto;
            }

            .form-enter-buttons-singup{
                display: flex;
                margin-top: 15px;
                margin-left: auto;
                margin-right: auto;

                a{
                    margin: auto 0;
                    font-size: 12px;
                }

                p{
                    font-size: 12px;
                    margin-right: 5px;
                }
            }
        }

            
    }
}

@media screen and (max-width: 576px) {
    .lk-signin-popup{
        width: 90%;
        left: 5%;
        right: 0;
    }
}
</style>