<template>
    <div class="modal-img" @click="hideImg()">
        <modal-img :imgSrc="openImgSrc" v-if="openImgSrc!==''"/>
    </div>

    <main>
        <div class="header">
        <div class="header-left-block">
            <h1>
                Арендовать паркинг в ЖК Серебряный Фонтан корпус {{ building }}
            </h1>
            <div class="header-left-block-filter">
                 
                <div class="filter-line-1">
                    <div class="filter-item">
                        <p>Стоимость</p>
                        <div class="filter-price">
                            <label>от</label>
                            <input type="text" v-model="filter.priceStart" >
                            <label>руб.</label>
                            <label>до</label> 
                            <input type="text" v-model="filter.priceEnd">
                            <label>руб.</label>
                        </div>
                    </div>

                    <div class="filter-item">
                        <p>Выберите размер м/м:</p>
                        <div class="filter-size">
                            <input type="radio" name="size" id="size1" value="13,3" v-model="filter.size" >
                            <label for="size1">13,3</label>
                            <br>
                            <input type="radio" name="size" id="size2" value="14,8" v-model="filter.size">
                            <label for="size2">14,8</label>
                        </div>
                    </div>

                    <div class="filter-item">
                        <p>Выберите этаж:</p>
                        <div class="filter-floor">
                            <input type="radio" name="floor" id="floor1" value="-1" v-model="filter.floor">
                            <label for="floor1">-1</label>
                            <br>
                            <input type="radio" name="floor" id="floor2" value="-2" v-model="filter.floor">
                            <label for="floor2">-2</label>
                        </div>
                    </div>

                    <div class="filter-item">
                        <select name="line" class="filter-section" v-model="filter.section">
                            <option disabled selected value="">Выбрать секцию</option>
                            <option v-for="section in 11" :value="section" :key="section">Секция {{ section }}</option>
                        </select>
                    </div>
                </div>
                
                <div class="filter-footer">
                    <a @click="resetFilter()">сбросить</a>
                    <button @click="applyFilter()">Показать</button>
                </div>

            </div>
        </div>
    </div>

    <div class="parking-list-block">
        <p>Найдено {{ parkingLots.length }} м/м</p>

        <div class="parking-list-table">
            <ul class="parking-list-table-line-header">
                <li>Номер м/м</li>
                <li>Размер</li>
                <li>Этаж</li>
                <li>Секция</li>
                <li>Стоимость</li>
            </ul>
            <ul class="parking-list-table-line" v-for="(item, index) in parkingLots" :key="index">
                <div class="line-content">
                    <li>{{ item.title }}</li>
                    <li>{{ item.size }} м&#178;</li>
                    <li>{{ item.floor }} этаж</li> 
                    <li>{{ item.section }} секция</li>
                    <li>{{ item.price }} руб./мес.</li>
                    <li class="line-content-img"><img :src="'https://sf16.ru/'+item.img" alt="" @click="openImg('https://sf16.ru/'+item.img)"></li>
                </div>
                <div class="line-buttons">
                    <li class="line-content-img-mob"><img :src="'https://sf16.ru/'+item.img" alt="" @click="openImg('https://sf16.ru/'+item.img)"></li>
                    <li><a :href="'https://sf16.ru/parking' + item.floor[1] + '.jpg'" target="_blank">план этажа</a></li>
                    <li><button @click="bookPlace(item.title)">Забронировать</button></li>
                </div>
            </ul>
            
        </div>
    </div>
    </main>
</template>

<script>
import axios from 'axios';
import ModalImg from '../components/ModalImg.vue'

export default {
    components:{
        ModalImg
    },

    props: {
        building: String
    },

  data() {
    return {
        parkingLots: [],
        filter: {
            priceStart: '',
            priceEnd: '',
            size: '',
            floor: '',
            section: '',
            building: this.$props.building
        },
        openImgSrc: ''
    }
  },

  methods: {
    loadData(){
        axios.post('https://sf16.ru/get_rent_places.php', {building: this.$props.building}).then((response)=>{
            console.log(response)
            this.parkingLots = response.data
        })
    },

    applyFilter(){
         console.log(this.filter)
         axios.post('https://sf16.ru/filter_rent_places.php', this.filter).then((response)=>{
            console.log(response)
            this.parkingLots = response.data
         })
    },

    resetFilter(){
        this.filter = {
            priceStart: '',
            priceEnd: '',
            size: '',
            floor: '',
            section: '',
            building: this.$props.building
        }
    },

    openImg(img){
        this.openImgSrc = img
    },

    hideImg(){
        this.openImgSrc = ''
    },

    bookPlace(title){
        if(Object.entries(this.$store.state.user_info).length === 0){
            alert('Войдите в личный кабинет')
        }
        else{
            axios.post('https://sf16.ru/book_user_place.php',
            {
                user_id: this.$store.state.user_info.id,
                place_title: title
            }).then((response)=>{
                console.log(response)
                alert('Успешно')
                this.loadData()
            })
        }
    }
  },
  
  beforeMount () {

    this.loadData()

  }
}
</script>

<style lang="scss">
.line-content-img-mob{
    display: none;
}
.header{
    display: flex;
    justify-content: space-between;
    margin-top: 135px;
    width: 100%;
    height: 100%;

    .header-left-block{
        width: 100%;
        padding-bottom: 40px;
        h1{
            margin-top: 20px;
            text-align: center;
        }

        .header-left-block-filter{
            width: 100%;
        }

        .filter-header{
            margin-top: 50px;

            p{
                font-size: 18px;
                margin-left: 50px;
            }
        }

        .filter-line-1{
            display: flex;
            justify-content: space-around;
            margin-top: 50px;
        }

        .filter-item{
            display: flex;
            margin-top: 20px;
            font-size: 14px;

            p{
                margin-right: 10px;
            }

            input[type="radio"]{
                margin-right: 5px;
            }

            .filter-section{
                height: fit-content;
                outline: none;
                border: none;
                background: #fff;
                font-size: 14px;
            }

            .filter-price{
                display: flex;
                input{
                    margin: 0 5px 0 10px;
                    width: 70px;
                    font-size: 14px;
                    height: 18px;
                }

                label:nth-child(4){
                    margin-left: 20px;
                }
            }

            .filter-size, .filter-floor{
                line-height: 1.5;
            }

        }

        .filter-footer{
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;

            a{
                font-size: 12px;
                color: #6d6d6d;
                text-decoration: underline;
                margin-right: 20px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }
}

.parking-list-block{
    margin-top: 50px;
    min-height: 30vh;

    p{
        font-size: 14px;
        color: #6d6d6d;
    }

    .parking-list-table{
        width: 100%;
        margin-top: 30px;

        ul{
            display: flex;
            list-style-type: none;

            li{
                
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        .parking-list-table-line-header{

            li{
                color: #6d6d6d;
                width: 10%;
                font-size: 12px;
                margin-bottom: 20px;
            }
        }

        .parking-list-table-line{
            justify-content: space-between;
            height: 70px;
            border-bottom: 1px solid #6d6d6d;

            li{
                font-size: 14px;
            }

            div{
                display: flex;
            }
            .line-content{
                width: 60%;

                li{
                    width: 20%;
                }

                .line-content-img img{
                    height: 50px;
                    width: auto;
                    cursor: pointer;
                }
            }

            .line-buttons{
                width: auto;

                a{
                    font-size: 12px;
                    color: #6d6d6d;
                    text-decoration: underline;
                    margin-right: 18px;
                }
            }

            
            
        }
    }
}

@media screen and (max-width: 576px){
    .header{
        .header-left-block{
            h1{
                margin-top: 0;
            }

            .filter-line-1{
                display: block;

                .filter-item{
                    margin-bottom: 50px;
                }
            }
        }
    }
    
    .parking-list-table-line-header{
        li{
            width: 20% !important;
        }
    }

    .parking-list-table-line{
        display: block !important;
        height: auto !important;
        padding-bottom: 20px;
        
        .line-content{
            width: 100% !important;
            margin-top: 20px;

            li{
                width: 20%;
            }

            .line-content-img{
                display: none;
            }

            
        }

        .line-buttons{
            margin-top: 30px;
            justify-content: flex-end;

            .line-content-img-mob{
                display: block;
                margin-right: auto;

                img{
                    height: 50px;
                    width: auto;
                    cursor: pointer;
                }
            }
        }
    }
}

</style>